module.exports = {
  sassVariables: {
    primary: '#F99E29',
    secondary: '#000000',
    'navbar-back-rotation': '50deg',
    'navbar-link-rotation': '-90deg',
    'login-background': "url('/images/bg_black.png')",
    'secondary-background': "''",
  },
  images: {
    loginLogo: '/images/logo.png',
    fullLogo: '/images/logo-horizontal.png',
    overviewLogo: '/images/logo-horizontal.png',
    faviconLogo: '/images/favicons.png',
    poweredByAdvocate: '/images/powered-by-advocate.png',
  },
}
